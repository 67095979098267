'use client';

import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Input,
  LinkBox,
  LinkOverlay,
  SimpleGrid,
  Spinner,
  Stack,
  Text,
} from '@chakra-ui/react';
import NextLink from 'next/link';

import PageContainer from '@components/PageContainer';
import { useRootContext } from '@components/Providers';
import useToast from '@components/Toast';
import client, {
  defaultLocale as defaultLocaleString,
  locale as localeString,
  localeData,
  noDraft,
  sanityImage,
} from '@lib/integrations/sanity';
import { validateEmail } from '@raise/common';
import type { BlogDocument, PostDocument } from '@raise/sanity';

import { getCategoryColor } from './_helpers';

import { GetDataProps } from '../types';

type BlogProps = BlogDocument & {
  initialPosts: PostDocument[];
  initialPostsMetadata: {
    lastId: string | null;
    lastPosted: string | null;
  };
  toasts: GetDataProps['toasts'];
};

const Blog: React.FC<BlogProps> = ({
  initialPosts,
  initialPostsMetadata,
  toasts,
  blog,
}) => {
  const [allPosts, setAllPosts] =
    useState<BlogProps['initialPosts']>(initialPosts);
  const [allPostsMetadata, setAllPostsMetadata] =
    useState<BlogProps['initialPostsMetadata']>(initialPostsMetadata);
  const [value, setValue] = useState('');
  const isValidEmail = value !== '' && validateEmail(value);

  const toastToDisplay = useToast(toasts.newsletter_subscribe);

  const onSubmit = () => {
    if (isValidEmail) {
      fetch(`/api/newsletter`, {
        method: 'POST',
        body: JSON.stringify({
          email: value,
        }),
      })
        .then((r) => r.json())
        .then((res) => {
          if (res.success) {
            toastToDisplay.success();
          } else {
            toastToDisplay.error();
          }

          setValue('');
        });
    }
  };

  const loadMorePosts = async () => {
    if (allPostsMetadata.lastId === null) {
      return;
    }

    const sort = `(posted < $lastPosted || (posted == $lastPosted && _id < $lastId))`;

    // @ts-ignore Sanity-typed can't generate types like this
    const result = (await client.fetch(
      `coalesce(
        *[_type == "post" && ${localeString} && ${noDraft} && ${sort}],
        *[_type == "post" && ${defaultLocaleString} && ${noDraft} && ${sort}]
      ) | order(posted desc) [0...12]`,
      { ...allPostsMetadata, ...localeData },
    )) as any[];

    if (result.length > 0) {
      setAllPostsMetadata({
        lastId: result[result.length - 1]._id,
        lastPosted: result[result.length - 1].posted,
      });

      setAllPosts([...allPosts, ...result]);
    } else {
      setAllPostsMetadata({
        lastId: null,
        lastPosted: null,
      });
    }
  };

  useEffect(() => {
    loadMorePosts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box bg="gray.50" pt={32}>
      <PageContainer py={[8, null, 16, null, 24]}>
        <Stack spacing={[16, null, 24]}>
          <Stack spacing={[8, null, 10]} align="center">
            <Stack spacing={[4, null, 6]} textAlign="center">
              <Stack spacing={4}>
                <Text fontWeight="semibold" color="brand.500" fontSize="md">
                  {blog.title}
                </Text>
                <Heading size={['xl', null, '2xl']}>{blog.subtitle}</Heading>
              </Stack>
              <Text fontSize={['lg', null, 'xl']} maxW="2xl" color="gray.500">
                {blog.description}
              </Text>
            </Stack>
            <Stack
              spacing={4}
              direction={['column', null, 'row']}
              width="full"
              justify="center"
            >
              <Input
                size="lg"
                type="email"
                placeholder={blog.subscribe.input}
                maxW={[null, null, 'sm']}
                bg="white"
                color="gray.800"
                _focus={{
                  borderColor:
                    isValidEmail || value === '' ? 'brand.500' : 'red.500',
                }}
                errorBorderColor="red.500"
                value={value}
                onChange={(e) => setValue(e.target.value)}
                isInvalid={!isValidEmail && value !== ''}
              />
              <Button size="lg" onClick={onSubmit}>
                {blog.subscribe.button}
              </Button>
            </Stack>
          </Stack>
          <InfiniteScroll
            dataLength={allPosts.length}
            next={loadMorePosts}
            hasMore={allPostsMetadata.lastId !== null}
            loader={
              <Flex pt={4} justifyContent="center">
                <Spinner size="lg" />
              </Flex>
            }
            style={{ overflowY: 'hidden', paddingBottom: '2rem' }}
          >
            <SimpleGrid columns={[1, null, 2, 3]} rowGap={8} columnGap={8}>
              {allPosts.map((post) => (
                <LinkBox
                  key={post.slug.current}
                  p={6}
                  bg="white"
                  borderRadius="md"
                  boxShadow="md"
                  transition="all 0.2s"
                  height="full"
                  cursor="pointer"
                >
                  <Stack spacing={6}>
                    <Box overflow="hidden">
                      <Image
                        src={
                          post.image ? sanityImage(post.image).url() : undefined
                        }
                        alt={post.title}
                        width="full"
                        height="15rem"
                        objectFit="cover"
                        borderRadius="md"
                      />
                    </Box>
                    <Stack spacing={2}>
                      <LinkOverlay
                        as={NextLink}
                        href={`/blog/${post.slug.current}`}
                      >
                        <Text
                          fontSize="sm"
                          fontWeight="semibold"
                          color={getCategoryColor(post.category)}
                        >
                          {post.category}
                        </Text>
                      </LinkOverlay>
                      <Heading size="lg" lineHeight="normal">
                        {post.title}
                      </Heading>
                      <Text color="gray.500">
                        {post.excerpt.slice(0, 140)}
                        {post.excerpt.length > 140 && '...'}
                      </Text>
                    </Stack>
                  </Stack>
                </LinkBox>
              ))}
            </SimpleGrid>
          </InfiniteScroll>
        </Stack>
      </PageContainer>
    </Box>
  );
};

export default Blog;
